body {
  margin-bottom: 50px;
}

small {
  &.normal {
    font-weight: normal;
  }
}

.register-form {
  margin-bottom: 20px;
}